jQuery(document).ready(function($) {

new WOW().init();

var mod = $('.service-module').width();
$('.service-module').css({'height':mod+'px'});

$('.testimonials-slider').owlCarousel({
   autoplay: true,
    loop:true,
    margin:10,
    nav:false,
   dots: true,
    items:1,
    autoHeight: true
});

// Nav Icon ========================= //
 $('.nav-icon').click(function(){
	$(this).toggleClass('hamburger-active');
	$('nav').toggleClass('menu-open');
  });

}); /* end of as page load scripts */
